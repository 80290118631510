import React from "react";
import { Theme } from "../../Themes/theme";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import imageList from "../../Assets/Images/checklist-1.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import BotaoWhats from "../../Components/BotaoWhats/BotaoWhats";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Documentos = () => {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignContent: "center",
          marginRight: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
          marginLeft: { xs: "0px", sm: "64px", md: "156px", lg: "256px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
            }}
          >
            LISTA DE DOCUMENTOS
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: { xs: "14px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Na hora de realizar a sua declaração de Imposto de Renda, alguns
            documentos são essenciais. Leia o texto abaixo e saiba quais
            documentos você precisa para realizar a sua declaração.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box maxWidth={"600px"}>
            <img src={imageList} alt="list document" width={"100%"}></img>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: { xs: "14px", md: "16px" },
              justifyContent: "center",
              color: "rgba(0, 0, 0, 0.7)",
            }}
          >
            Veja quais são os principais documentos para declaração do Imposto
            de Renda no ano de 2023
          </Typography>
        </Box>

        <Box>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                INFORMAÇÕES SOBRE O CONTRIBUINTE E SEUS DEPENDENTES
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Nome, CPF, Título de Eleitor, Endereço atualizado,
                      Telefone e Email
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Atividade profissional exercida atualmente
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Cópia completa da última Declaração de Imposto de Renda da
                      Pessoa Física
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Dependentes: Nome, CPF, grau de parentesco e data de
                      nascimento.
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                INFORMAÇÕES SOBRE BENS E CONTAS DO CONTRIBUINTE
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Para imóveis: data de aquisição, área do imóvel, inscrição
                      municipal (IPTU), registro de inscrição no órgão público e
                      registro no cartório de imóveis
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Para veículos, aeronaves e embarcações: número do Renavam
                      e/ou registro no órgão fiscalizador correspondente
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Para contas correntes e aplicações financeiras: Informes
                      de Rendimento, contendo CNPJ, agência e conta.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Para todas as aquisições e/ou venda de bens, é necessário
                      informar a forma de pagamento/recebimento do bem, com
                      informações do financiamento (número do contrato e valores
                      pagos no ano), se for o caso e os dados do comprador,
                      vendedor, bem como seu CPF ou CNPJ{" "}
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                DOCUMENTOS RELACIONADOS AOS RENDIMENTOS DO CONTRIBUINTE E DOS
                DEPENDENTES
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Informes de rendimentos de salários, aposentadoria,
                      pensões, pró-labore, distribuição de lucros
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Informes de rendimentos de aluguéis de bens móveis e
                      imóveis
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Informes de outras rendas recebidas em 2022, como doações,
                      heranças e pensão alimentícia
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Informes de rendimentos de instituições financeiras, como
                      bancos, bancos digitais e corretoras de valores e de
                      investimentos
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Informes de rendimentos de programas fiscais, como Nota
                      Fiscal Paulista e Nota Fiscal Paulista
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Dados do Carnê-Leão para importação na Declaração do IR
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                DOCUMENTOS RELACIONADOS A PAGAMENTOS E DEDUÇÕES EFETUADAS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Recibos ou informes de rendimentos de plano ou seguro de
                      saúde (com CNPJ da empresa emissora e indicação do
                      paciente)
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Despesas médicas e odontológicas em geral (com CNPJ da
                      empresa emissora ou CPF do profissional e indicação do
                      paciente) – recibos, notas fiscais, extrato do plano de
                      saúde que evidencie os gastos
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Comprovantes de despesas com educação (com CNPJ da empresa
                      emissora e indicação do aluno)
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Comprovante de pagamento de previdência social e privada
                      (com CNPJ da empresa emissora)
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Recibos de doações feitas</ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                DOCUMENTOS RELACIONADOS A DÍVIDAS E ÔNUS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Informes e documentos de dívida e ônus contraídos e/ou
                      pagos no ano passado, mas, atenção: nem todo mundo que
                      teve dívida precisa deste documento, apenas se a quantia
                      devida for maior do que R$ 5 mil. Também não é necessário
                      declarar dívidas de financiamento imobiliário, bens
                      adquiridos por consórcio e dívidas de atividade rural
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: "#00259c",
                border: "1px solid white",
                borderRadius: "4px",
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                DOCUMENTOS REFERENTES A RENDAS VARIÁVEIS
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Demo>
                <List>
                  <ListItem>
                    <ListItemText>
                      Notas de corretagem e extratos de Imposto de Renda
                      enviadas pelas corretoras a quem faz negociação de renda
                      variável
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Documentos de Arrecadação da Receita Federal (DARFs) de
                      Renda Variável
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      Informes de rendimentos adquiridos com renda variável
                    </ListItemText>
                  </ListItem>
                </List>
              </Demo>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Paper
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
          elevation={3}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
            }}
          >
            <Alert severity="info" sx={{ width: "100%", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >
                Preciso de <strong> TODOS </strong> esses documentos ?
              </Typography>
            </Alert>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
              }}
            >
              <strong>Não! </strong>Essa é apenas uma lista geral com os
              principais documentos necessários, você precisa ter aqueles que se
              aplicam à sua realidade em 2022.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
              }}
            >
              <strong>Por exemplo:</strong> se você não comprou nem vendeu um
              veículo em 2022, não precisa dos documentos relacionados a ele.
              Agora se você recebeu salários e comprou um carro, precisa do
              informe da fonte pagadora, e do documento do veículo,
              respectivamente.
            </Typography>
          </Box>
        </Paper>
        <Box
          sx={{
            display: { xs: "", md: "flex" },
            flexDirection: { xs: "", md: "row" },
            justifyContent: { xs: "", md: "center" },
          }}
        >
          <BotaoWhats />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Documentos;
