import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Declaracao from "../Pages/Declaracao/Declaracao";
import Documentos from "../Pages/Documentos/Documentos";
import Faq from "../Pages/Faq/Faq";
import Calculadora from "../Pages/Calculadora/Calculadora";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Dashboard>
        <Routes>
          <Route
            path="/*"
            element={<Navigate to={{ pathname: "/declaracao2023" }} />}
          />
          <Route path="/declaracao2023" element={<Declaracao />} />
          <Route path="/documentos" element={<Documentos />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/calculadora" element={<Calculadora />} />
        </Routes>
      </Dashboard>
    </BrowserRouter>
  );
};

export default AppRoutes;
