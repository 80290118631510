import { createTheme } from "@mui/material";

export const Theme = createTheme({
  palette: {
    primary: {
      main: "#00259c",
      dark: "#291ad9",
      light: "#00ADFF",
      white: "#fff",
      contrastText: "#fff",
    },

    secondary: {
      main: "#e0e0e0",
      dark: "#7A7A7A",
      light: "#EDEDED",
      white: "#fff",
    },
    error: {
      main: "#AD0000",
      dark: "#8D1616",
      light: "#F17070",
      white: "#fff",
    },

    warning: {
      main: "#FF9C31",
      dark: "#F08A4E",
      light: "#FFDC74",
      white: "#fff",
    },

    information: {
      main: "#00ADFF",
      dark: "#0E8ECA",
      light: "#4AC2FB",
      white: "#fff",
    },

    success: {
      main: "#3F6213",
      dark: "#517E18",
      light: "#83B445",
      white: "#fff",
    },

    action: {
      active: "#7A7A7A",
      hover: "#FBFBFB",
      selected: "#EDEDED",
      disabled: "#ABABAB",
      disabledBackground: "#e0e0e0",
      focus: "#e0e0e0",
    },

    background: {
      divider: "#fafa",
      outlineBorder: "#FFFFFF",
    },

    neutral: {
      main: "#FFFFFF",
    },
  },

  typography: {
    allVariants: {
      fontFamily: "Verdana",
      fontWeight: "normal",
    },
  },
});
